<div
  #wrapper
  class="wrapper"
  (mouseleave)="sidebarMouseleave($event)"
>
  <app-navbar-dx29></app-navbar-dx29>
  <div class="main-panel">
    <div class="main-content">
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </div>
  </div>
</div>
