    export const environment = {
    production: true,
    api: 'https://dx29.ai',
    serverapi: 'https://dxgpt.app',
    ncrService: 'https://ncrhealth29v2.azurewebsites.net/api/ncr-dev?code=QDnS6Fv2SJgFauPKJgimctizEpWBVNMWdhoRKut9HragTSUoQQB5jw==',
    f29bio: 'https://f29bio.northeurope.cloudapp.azure.com',
    f29api: 'https://f29api.northeurope.cloudapp.azure.com',
    hotjarSiteId: '3279828',
    urlDxv2: 'https://app.dx29.ai',
    INSTRUMENTATION_KEY: '0534798e-09ad-45c9-a864-d0a1654d172e',
    Server_Key: '464dcafc-aba0-4a6b-9e09-4c7f9ff9b7a5',
    GA_ID: 'G-2FZQ49SRWY',
    GA_SecondId: 'AW-335378785',
    GA_Conversion_ID: 'AW-335378785/wcKYCMDpnJIZEOHy9Z8B'
    };
