<a id="initpopup" class="anchorSteps"></a>
<div *ngIf="!isDirectRoute()" class="modal-header">
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close('Close click')">
  </button>
</div>
<div class="row" [ngClass]="(!isDirectRoute())?'':'card mt-0'">
  <section [ngClass]="(!isDirectRoute())?'col-md-12':'offset-md-2 col-md-8'">
    <div class="modal-header mt-2">
      <h4 class="modal-title">{{'menu.Privacy Policy' | translate }}</h4>
    </div>
    <div class="modal-body">
      <p>{{'policy.p1' | translate }}</p>
      <p class="mb-0"><strong>{{'policy.p2.1' | translate }}</strong></p>
      <ul>
        <li><p class="mb-0">{{'policy.p2.2' | translate }}</p></li>
        <li><p>{{'policy.p2.3' | translate }}</p></li>
      </ul>
      <p class="mb-0"><strong>{{'policy.p3.1' | translate }}</strong></p>
      <ul>
        <li><p class="mb-0">{{'policy.p3.2' | translate }}</p></li>
        <li><p>{{'policy.p3.4' | translate }}</p></li>
        <li><p>{{'policy.p3.3' | translate }}</p></li>
      </ul>
      <p class="mb-0"><strong>{{'policy.p4.1' | translate }}</strong></p>
      <ul>
        <li><p class="mb-0">{{'policy.p4.2' | translate }}</p></li>
        <li><p class="mb-0">{{'policy.p4.3' | translate }}</p></li>
        <li><p>{{'policy.p4.4' | translate }}</p></li>
      </ul>
      <p class="mb-0"><strong>{{'policy.p5.1' | translate }}</strong></p>
      <ul>
        <li><p class="mb-0">{{'policy.p5.2' | translate }}</p></li>
        <li><p>{{'policy.p5.3' | translate }}</p></li>
      </ul>
      <p class="mb-0"><strong>{{'policy.p6.1' | translate }}</strong></p>
      <ul>
        <li><p class="mb-0">{{'policy.p6.2' | translate }}</p></li>
        <li><p>{{'policy.p6.3' | translate }}</p></li>
      </ul>
      <p>{{'policy.p7' | translate }}</p>
      <p>Fundacion 29 de Febrero</p>
    </div>
  </section>
</div>
<div *ngIf="!isDirectRoute()" class="modal-footer">
  <button class="btn btn-info" (click)="activeModal.close('Close click')">{{'generics.Close' | translate }}</button>
</div>