<!--Footer Starts-->
<footer>
    <div class="container-fluid pt-2" style="background-color: #ffffff !important;">
      <div class="row margin-0 center-elements">
        <div class="col-lg-12">
          <div class="row">
            <div [ngClass]="(!isHomePage)?'col-md-6':'col-md-4'" *ngIf="!isPolicyPage">
              <p>
                <a href="javascript:;" class="underline" (click)="openModalPolicy()">
                  <span>{{'menu.Privacy Policy' | translate }}</span>
                </a>
              </p>
            </div>
            <div [ngClass]="(!isHomePage)?'d-none':'col-md-4'">
              <p>
                <span class="pointer underline" (click)="openSupport(contentSubs);">{{'land.Subscribe or send us a message' | translate }}</span>
              </p>
    
            </div>
            <div [ngClass]="(!isHomePage)?'col-md-6':'col-md-4'" *ngIf="!isCookiesPage">
              <p>
                <a href="javascript:;" class="underline" (click)="openModalCookies()">
                  <span>{{'cookies.title' | translate }}</span>
                </a>
              </p>
            </div>
          </div>
        </div>
        
        
      </div>

    </div>
</footer>
<ng-template #contentSubs let-c="close" let-d="dismiss">
  <a id="initpopup" class="anchorSteps"></a>
  <div class="modal-header">
    <h4 class="modal-title">DxGPT support</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeSupport()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="form" #f="ngForm">
      <div class="form-group">
        <span class="mb-0">{{'generics.Name' | translate }} *</span>
        <div>
          <input class="form-control" type="text" id="userName" name="userName" placeholder="{{'generics.Name' | translate }}" ngModel required #userNameModel="ngModel" [(ngModel)]="userName">
          <small class="danger" *ngIf="!userNameModel.valid && showErrorForm">{{'generics.required' | translate }}</small>
        </div>
      </div>
      <div class="form-group">
        <span class="mb-0">{{'generics.Email' | translate }} *</span>
        <div>
          <input class="form-control" type="email" id="Email" name="Email" placeholder="{{'generics.Email' | translate }}" ngModel required #Email="ngModel" autocomplete="email" email name="Email" [(ngModel)]="email">
          <small class="danger" *ngIf="!Email.valid && showErrorForm">{{'generics.entervalidemail' | translate }}</small>
        </div>
      </div>
      <div class="form-group">
        <span>{{'support.Message' | translate }} ({{'generics.Optional' | translate }}):</span>
        <div>
          <textarea maxlength="2000" #autoajustable id="textarea1" type="text" name="msgfeedBack" class="autoajustable data-hj-allow form-control" style="width: 100%;" [(ngModel)]="msgfeedBack" (input)="autoResize($event)"></textarea>
          <p class="float-end">{{ msgfeedBack.length }} / 2000</p>
        </div>
      </div>
      <div class="">
        <mat-checkbox
            class="example-margin" name="terms3" ngModel #terms3="ngModel" id="terms3"  [(ngModel)]="checkSubscribe">
            <span class="mat-checkbox-label" >{{'land.Email me' | translate }}</span>
        </mat-checkbox>
      </div>
      <div class="form-group">
        <mat-checkbox
            class="example-margin" name="termscontrol" ngModel #terms="ngModel" id="termscontrol" required [(ngModel)]="acceptTerms">
            <span class="mat-checkbox-label">{{'termsform.accept' | translate }} <a href="javascript:;" (click)="openModal(termsModal)">{{'termsform.terms' | translate }}</a></span>
        </mat-checkbox>
        <small class="danger d-block" *ngIf="!terms.valid && showErrorForm">{{'termsform.acceptterms' | translate }}</small>
      </div>
      <div *ngIf="!sending" class="mt-2 col-md-12 center-elements">
        <div *ngIf="f.valid;then formValid else formError"></div>
        <button class="btn btn-secondary ms-1" type="button" (click)="closeSupport()">{{'generics.Cancel' | translate }}</button>
        <ng-template #formValid><button class="btn btn-info me-1" (click)="onSubmitRevolution();"><strong>{{'generics.Send' | translate }}</strong></button></ng-template>
        <ng-template #formError><button class="btn btn-info me-1" type="button" (click)="submitInvalidForm()"><strong>{{'generics.Send' | translate }}</strong></button></ng-template>
      </div>
      <div class="form-actions col-md-12 center-elements" *ngIf="sending">
          <span class=""><i class="fa fa-spinner fa-spin fa-3x fa-fw pink"></i></span>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #termsModal let-c="close" let-d="dismiss">
  <app-termsform (termsAccepted)="onTermsAccepted()"></app-termsform>
</ng-template>