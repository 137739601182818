<a id="initpopup2" class="anchorSteps"></a>
<div class="modal-header">
  <h4 class="modal-title">{{'termsform.modalTitle' | translate }}</h4>
</div>
<div class="modal-body">
  <p>{{'termsform.modalBody2' | translate }}</p>
  <ul>
    <li>{{'termsform.modalBody3' | translate }}</li>
    <li>{{'termsform.modalBody4' | translate }}</li>
  </ul>
  <p>{{'termsform.modalBody5' | translate }}</p>
  <ul>
    <li>{{'termsform.modalBody6' | translate }}</li>
  </ul>
  <p>{{'termsform.modalBody7' | translate }}</p>
  <ul>
    <li>{{'termsform.modalBody8' | translate }}</li>
  </ul>
  <h5>{{'termsform.modalBody9' | translate }}</h5>
  <p>{{'termsform.modalBody10' | translate }}</p>
  <p>{{'termsform.modalBody11' | translate }}</p>
  <h5>{{'termsform.modalBody12' | translate }}</h5>
  <p>{{'termsform.modalBody13' | translate }}</p>
  <p>{{'termsform.modalBody14' | translate }}</p>
  <h5>{{'termsform.modalBody15' | translate }}</h5>
  <p>{{'termsform.modalBody16' | translate }}</p>
</div>
<div class="modal-footer">
  <button class="btn btn-info" (click)="onAcceptTerms()">{{'termsform.modalFooterButton' | translate }}</button>
</div>