<a id="initpopup" class="anchorSteps"></a>
<div *ngIf="!isDirectRoute()" class="modal-header">
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close('Close click')">
  </button>
</div>
<div class="row" [ngClass]="(!isDirectRoute())?'':'card mt-0'">
  <section [ngClass]="(!isDirectRoute())?'col-md-12':'offset-md-2 col-md-8'">
    <div class="modal-header mt-2">
      <h4 class="modal-title">{{'cookies.title' | translate }}</h4>
    </div>
  <div class="modal-body">
    <div class="px-3">
      <div class="form-body">
        <div class="row">
          <div class="col-sm-12">
            <h6 class="pt-2 text-bold-600">{{'cookies.t1' | translate }}</h6>
            <p [innerHTML]="'cookies.p1.1' | translate "></p>
            <p>{{'cookies.p1.2' | translate }}</p>
            <h6 class="pt-2 text-bold-600">{{'cookies.t2' | translate }}</h6>
            <p>{{'cookies.p2.1' | translate }}</p>
            <h6 class="pt-2 text-bold-600">{{'cookies.t3' | translate }}</h6>
            <p>{{'cookies.p3.1' | translate }}</p>
            <h6 class="pt-2 text-bold-600">{{'cookies.t4' | translate }}</h6>
            <p>{{'cookies.p4.1' | translate }}</p>
            <h6 class="pt-2 text-bold-600">{{'cookies.t5' | translate }}</h6>
            <p [innerHTML]="'cookies.p5.1' | translate "></p>
            <p [innerHTML]="'cookies.p5.2' | translate "></p>
            <ul>
              <li>
                {{'cookies.p5.2.1' | translate }}
              </li>
              <li>
                {{'cookies.p5.2.2' | translate }}
              </li>
            </ul>
            <p>{{'cookies.p5.3' | translate }}</p>
            <h6 class="pt-2 text-bold-600">{{'cookies.t6' | translate }}</h6>
            <p>{{'cookies.p6.1' | translate }}</p>

            <ul class="mt-2">
              <li>
                <a class="underline" href="https://support.google.com/chrome/answer/95647?hl={{lang}}" target="_blank">Google Chrome.</a>
              </li>
              <li>
                <a class="underline" href="https://support.microsoft.com/{{lang}}/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                  target="_blank">Internet Explorer.</a>
              </li>
              <li>
                <a class="underline" *ngIf="lang=='es'"
                  href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias"
                  target="_blank">Mozilla Firefox.</a>
                <a class="underline" *ngIf="lang!='es'"
                  href="https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox"
                  target="_blank">Mozilla Firefox.</a>
              </li>
              <li>
                <span class="danger" *ngIf="lang=='es'">
                  <a class="underline" href="https://support.apple.com/es-es/HT201265" target="_blank">Safari iPhone, iPad
                    {{'generics.and' | translate }} iPod touch</a>
                  & <a class="underline" href="https://support.apple.com/es-es/guide/safari/sfri11471/mac"
                    target="_blank">Safari iPhone, iPad {{'generics.and' | translate }} Safari para Mac.</a>
                </span>
                <span class="danger" *ngIf="lang!='es'">
                  <a class="underline" href="https://support.apple.com/en-en/HT201265" target="_blank">Safari iPhone, iPad
                    {{'generics.and' | translate }} iPod touch</a>
                  & <a class="underline" href="https://support.apple.com/en-en/guide/safari/sfri11471/mac"
                    target="_blank">Safari iPhone, iPad {{'generics.and' | translate }} Safari para Mac.</a>
                </span>

              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    </div>
  </section>
</div>
<div *ngIf="!isDirectRoute()" class="modal-footer">
  <button class="btn btn-info" (click)="activeModal.close('Close click')">{{'generics.Close' | translate }}</button>
</div>